import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Auth, API, graphqlOperation } from 'aws-amplify';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import gql from 'graphql-tag';

import UserClient from "../../clients/UserClient";

const propTypes = {
  alerts: PropTypes.bool,
  acct: PropTypes.bool,
};
const defaultProps = {
  alerts: false,
  acct: false
};

const NEW_MESSAGE = gql(`
    subscription alertAcknownledgementUpdated($userId: String!) {
      alertAcknownledgementUpdated(userId: $userId) {
        userId
        creationDateTime
        }
    }`);

const NEW_ALERT = gql(`
    subscription newAlert($userId: String!) {
      newAlert(userId: $userId) {
        userId
        sensorId
        }
    }`);

const ALL_UNREAD_SENSOR_ALERTS = gql`
  query GetUnreadSensorAlerts {
    getUnreadSensorAlerts {
      userId
      name
      sensorId
      value
      acknowledged
      notified
      level
      startedDateTime
      newAlert
    }
  }`;

class DefaultHeaderDropdown extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.subscribeEvent = this.subscribeEvent.bind(this);
    this.state = {
      dropdownOpen: false,
      itemsCount: 0,
      unreadSensorAlerts: [],
      userName: ""
    };
    this.logoutHandler = this.logoutHandler.bind(this);

    UserClient.getUserData().then(userData => {
      this.subscribeEvent(userData.userId, userData.userName, this.props);
    });
  }

  subscribeEvent = (userId, userName, props) => {
    const clientId = userId.split(':')[1] + '_' + new Date().getTime();
    
    this.setState({userId : userId, clientId : clientId, userName: userName});
    if(props.alerts) {
      API.graphql(graphqlOperation(NEW_MESSAGE, {userId: userId})).subscribe({
        next: async () => {
          const allPosts = await API.graphql(graphqlOperation(ALL_UNREAD_SENSOR_ALERTS));
          const unreadAlerts = allPosts.data.getUnreadSensorAlerts;
          unreadAlerts.sort((a, b) => {
            return a.startedDateTime > b.startedDateTime ? -1 : 1;
          });

          var event = new CustomEvent('acknowledgementUpdated', { detail: unreadAlerts, userId: userId });
          document.dispatchEvent(event);
          this.setState({
            itemsCount: unreadAlerts.length,
            unreadSensorAlerts: unreadAlerts
          });
        }
      });

      API.graphql(graphqlOperation(NEW_ALERT, {userId: userId})).subscribe({
        next: async (todoData) => {
          const newAlertData = todoData.value.data.newAlert;
          var event = new CustomEvent('newAlert', { detail: newAlertData });
          document.dispatchEvent(event);
          const allPosts = await API.graphql(graphqlOperation(ALL_UNREAD_SENSOR_ALERTS));
          const unreadAlerts = allPosts.data.getUnreadSensorAlerts;
          unreadAlerts.sort((a, b) => {
            return a.startedDateTime > b.startedDateTime ? -1 : 1;
          });
          this.setState({
            itemsCount: unreadAlerts.length,
            unreadSensorAlerts: unreadAlerts
          });
          if(newAlertData.level.startsWith('danger')) {
            toast.error('Sensor ' + newAlertData.name + ' has reached: ' + newAlertData.value);
          } else if(newAlertData.level.startsWith('warning')) {
            toast.warn('Sensor ' + newAlertData.name + ' has reached: ' + newAlertData.value);
          } else {
            toast.success('Sensor ' + newAlertData.name + ' back to normal: ' + newAlertData.value);
          }
        }
      });
    }
  }

  componentDidMount = async () => {
    const allPosts = await API.graphql(graphqlOperation(ALL_UNREAD_SENSOR_ALERTS));
    const unreadAlerts = allPosts.data.getUnreadSensorAlerts;
    unreadAlerts.sort((a, b) => {
      return a.startedDateTime > b.startedDateTime ? -1 : 1;
    });
      this.setState({
        itemsCount: unreadAlerts.length,
        unreadSensorAlerts: unreadAlerts
      });
  };

  logoutHandler(event) {
    Auth.signOut()
    .then(data => {
      window.location.reload();
    })
    .catch(err => console.log(err));
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  renderAlertDropdown = () => {
    let items = []

    this.state.unreadSensorAlerts.forEach((alert) => {
      if(items.length > 5) { return;}
      if(items.length > 4) {
        items.push(
          <DropdownItem key="empty">
          ...
          </DropdownItem>
        );
        return;
      }
      items.push(
        <DropdownItem key={alert.sensorId}>
          <div className="message">
            <div className="avatar pt-4 mr-3 float-left">
              <i className={(alert.level.startsWith('danger') ? 'custom-fire-critic critic'
                                                              : (alert.level.startsWith('warning') ? 'fa fa-warning warning'
                                                                                                  : 'fa fa-check ok'))}/>
              {alert.newAlert === 1 &&
                <span className="avatar-status badge-danger"></span>
              }
             
            </div>
            <div>
              <small className="text-muted">{alert.name}</small>
              <small className="text-muted float-right mt-1">{calculatePeriod(alert.startedDateTime)}</small>
            </div>
            <div className="text-truncate font-weight-bold">{alert.sensorId}</div>
            <div className="small text-muted text-truncate">Value reached {alert.value}
            </div>
          </div>
        </DropdownItem>
      )
    })
    return items
  }

  dropAlerts() {
    const containerStyle = {
      zIndex: 1999
    };
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} style={containerStyle}/>
        <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle nav>
          <i className="icon-bell"></i><Badge pill color={this.state.itemsCount > 0 ? 'danger' : 'success'}>{this.state.itemsCount}</Badge>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg nav-alerts">
            <DropdownItem header tag="div"><strong>You have {this.state.itemsCount} alerts</strong></DropdownItem>
            {
              this.renderAlertDropdown()
            }
            <DropdownItem href="#/alerts/list" className="text-center view-all"><strong>View all alerts</strong></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  dropAcct() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} className="acct_nav">
        <DropdownToggle nav>
          <span>{this.state.userName} </span><i className={this.state.dropdownOpen ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
          <DropdownItem href="#/users/profile"><i className="fa fa-user"></i> Profile</DropdownItem>
          <DropdownItem href="#/notifications/settings"><i className="fa fa-wrench"></i> Settings</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.logoutHandler}><i className="fa fa-lock"></i> Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    const { alerts, acct } = this.props;

    return (
            alerts ? this.dropAlerts() : 
            acct ? this.dropAcct() : null
    );
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;

function calculatePeriod(date) {
  var difference_ms = new Date().getTime() - new Date(date).getTime();
  //take out milliseconds
  difference_ms = difference_ms/1000;
  difference_ms = difference_ms/60;
  var minutes = displayIfNotZero(Math.floor(difference_ms % 60), ' min');
  difference_ms = difference_ms/60;

  var hours = displayIfNotZero(Math.floor(difference_ms % 24), ' hour', true);
  var days = displayIfNotZero(Math.floor(difference_ms/24), ' day', true);
  
  return days !== '' ? days :
          hours !== '' ? hours :
            minutes !== '' ? minutes :
              "Just now";
}

function displayIfNotZero(value, label, completWorld) {
  return value !== 0 ? value + label + (value > 1 && completWorld? 's ago' : ' ago') : '';
}