export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW'
    //   }
    // },
    {
      name: 'Sensors',
      icon: 'icon-feed',
      url: '/sensors/list'
    },
    {
      name: 'Gateways',
      icon: 'icon-cloud-upload',
      url: '/gateways/list'
    },
    {
      name: 'Activity Monitor',
      url: '/sensors/visualize',
      icon: 'fa fa-line-chart',
    },
    {
      name: 'Alerts',
      url: '/alerts/list',
      icon: 'icon-bell',
    },
    {
      name: 'Settings',
      url: '/notifications/settings',
      icon: 'cui-settings'
    },
    {
      name: 'Profile',
      url: '/users/profile',
      icon: 'icon-user'
    },
    {
      name: 'Billing',
      url: '/billing',
      icon: 'cui-dollar'
    },
  ]
};
