import {API} from 'aws-amplify';

const userApiName = 'ApiGatewayRestApi';
const userPath = '/users';
const settingPath = '/users/settings';

export default class UserClient {

  static getUserData = async (forceReload) => {
    if(localStorage.getItem("userLoggedIn") && !forceReload) {
      return JSON.parse(localStorage.getItem("userLoggedIn"));
    } else {
      const userData = await API.get(userApiName, userPath);
      localStorage.setItem("userLoggedIn", JSON.stringify(userData));
      return userData;
    }
  };

  static createUpdateSettings = (settings, callback) => {
    let requestParams = {
      body : settings
    };
    API.post(userApiName, settingPath, requestParams)
       .then(data => {
         callback(data);
       })
       .catch (err => {
         callback(null, err);
       })
  };

  static updateProfile = (dataForm, apiPath, callback) => {
    let requestParams = {
      body : dataForm
    };
    API.post(userApiName, userPath + '/' + apiPath, requestParams)
       .then(data => {
         callback(data);
       })
       .catch (err => {
         callback(null, err);
       })
  };

  static addPayment = (sourceId, callback) => {
    let requestParams = {
      body : { sourceId: sourceId }
    };
    API.post(userApiName, userPath + '/payment', requestParams)
       .then(data => {
         callback(data);
       })
       .catch (err => {
         callback(null, err);
       })
  };

  static deletePayment = (sourceId, callback) => {
    let requestParams = {
      body : { sourceId: sourceId }
    };
    API.del(userApiName, userPath + '/payment', requestParams)
       .then(data => {
         callback(data);
       })
       .catch (err => {
         callback(null, err);
       })
  };

  static listPayments = async () => {
    const payments = await API.get(userApiName, userPath + '/payment');
    return payments;
  };

  static getSettings = async () => {
    const settings = await API.get(userApiName, settingPath);
    return settings;
  };

}