import React from 'react';
import { AuthPiece } from 'aws-amplify-react';
import {Container, Row, Col, CardGroup, Card, CardBody, Form, Input, InputGroup} from 'reactstrap';
import { Auth, JS, API } from 'aws-amplify';
import LaddaButton from 'react-ladda';


class Login extends AuthPiece {

  constructor(props) {
    super(props);

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.state = {
      username: null,
      password: null,
      error: null,
      loading: false,
      hasError: false
    }

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(info);
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(async data => {
        if (!JS.isEmpty(data.verified)) {
          try {
            const userData = await API.post("ApiGatewayRestApi", "/users");
            localStorage.setItem("userLoggedIn", JSON.stringify(userData));
          } catch (ex) {
            console.log(ex);
          }

          this.changeState('signedIn', user);
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  signIn(event) {
    event.preventDefault();
    this.setState({
      loading: true
    })
    const { username, password } = this.state;
    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.changeState('requireNewPassword', user);
          this.setState({
            loading: false
          })
        } else {
          this.checkContact(user);
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        })
        this.error(err)
      });
  }

  onChangeText(key, event) {
    this.setState({ [key]: event.target.value })
  }

  showComponent(theme) {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-user"></i>
                          </span>
                        </div>
                        <Input type="text" placeholder="Username" autoComplete="username" onChange={event => {this.onChangeText('username', event)}}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input type="password" placeholder="Password" autoComplete="current-password" onChange={event => {this.onChangeText('password', event)}} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <LaddaButton className="btn btn-primary btn-ladda"
                                      loading={this.state.loading}
                                      data-style="expand-right"
                                      data-color="blue"
                                      onClick={this.signIn}>
                            Login
                          </LaddaButton>
                        </Col>
                        <Col xs="6" className="text-right">
                          <LaddaButton className="px-0 btn btn-link"
                                      disabled={this.state.loading}
                                      data-style="expand-right"
                                      onClick={() => this.changeState('forgotPassword')}>
                            Forgot password?
                          </LaddaButton>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Create an account</h2>
                      <p>It is easy and quick. </p>
                      <LaddaButton className="mt-3 btn btn-primary btn-ladda active"
                                   disabled={this.state.loading}
                                   data-style="expand-right"
                                   onClick={() => this.changeState('signUp')}>
                        Register Now!
                      </LaddaButton>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
