import React from 'react';
import {Container, Row, Col, Card, CardBody, CardFooter, Input, InputGroup} from 'reactstrap';
import { Auth } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react';
import Button from 'react-bootstrap-button-loader';

class Register extends AuthPiece {

  constructor(props) {
    super(props);

    this._validAuthStates = ['signUp'];
    this.state = {
      username: null,
      password: null,
      password_repeated: null,
      email: null,
      signUpLoading: false,
      signUpDisabled: false,
      validPassword: undefined,
      validPasswordRepeated: undefined,
      validEmail: undefined
    }

    this.signUp = this.signUp.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validatePasswordRepeated = this.validatePasswordRepeated.bind(this);
  }

  signUp() {
    this.setState({
      signUpLoading: true,
      signUpDisabled: true
    })
    const { username, password, password_repeated, email } = this.state;
    if(password_repeated !== password) {
      this.setState({
        signUpLoading: false,
        signUpDisabled: false
      })
      return;
    }
    Auth.signUp(username, password, email)
      .then(data => {
        this.changeState('confirmSignUp', username);
      })
      .catch(err => {
        this.setState({
          signUpLoading: false,
          signUpDisabled: false
        })
        this.error(err)
      });
  }

  onChangeText(key, event) {
    this.setState({ [key]: event.target.value }, () => this.validateFields(key))
  }

  validateFields(key) {
    switch(key) {
      case 'email':
        if(this.state.email !== null) {
          var emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          this.setState({ validEmail: emailValid !== null })
        }
        break;
      case 'password':
      case 'password_repeated':
        this.setState({ validPassword: this.state.password !== null && this.state.password !== "" })
        this.setState({
          validPasswordRepeated:
            this.state.password === this.state.password_repeated
            && this.state.password !== null
            && this.state.password !== ""
        })
        break;
      default:
        break;
    }
  }

  validatePasswordRepeated() {

  }

  showComponent(theme) {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>Register</h1>
                  <p className="text-muted">Create your account</p>
                  <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-user"></i>
                      </span>
                    </div>
                    <Input type="text" placeholder="Username" autoComplete="username" onChange={event => {this.onChangeText('username', event)}}/>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">@</span>
                    </div>
                    <Input type="text"
                           placeholder="Email"
                           autoComplete="email"
                           valid={ this.state.validEmail }
                           invalid={ this.state.validEmail ? !this.state.validEmail : undefined }
                           onChange={event => {this.onChangeText('email', event)}}/>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-lock"></i>
                      </span>
                    </div>
                    <Input type="password"
                           placeholder="Password"
                           autoComplete="new-password"
                           valid={ this.state.validPassword }
                           invalid={ this.state.validPassword ? !this.state.validPassword : undefined }
                           onChange={event => {this.onChangeText('password', event)}}/>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-lock"></i>
                      </span>
                    </div>
                    <Input type="password"
                           placeholder="Repeat password"
                           autoComplete="new-password"
                           valid={ this.state.validPasswordRepeated }
                           invalid={ this.state.validPasswordRepeated ? !this.state.validPasswordRepeated : undefined }
                           onChange={event => {this.onChangeText('password_repeated', event)}}/>
                  </InputGroup>
                  <Button bsStyle="success"
                          loading={this.state.signUpLoading}
                          disabled={this.state.signUpDisabled}
                          block onClick={this.signUp}>
                    Create Account
                  </Button>
                </CardBody>
                <CardFooter className="p-2">
                  <Row>
                    <Col xs="6" sm="6">
                      <Button bsStyle="link" onClick={() => this.changeState('signIn')}><span>Back to Sign In</span></Button>
                    </Col>
                    <Col xs="6" sm="6" className="text-right">
                      <Button bsStyle="link" onClick={() => this.changeState('confirmSignUp')}><span>Confirm code</span></Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
