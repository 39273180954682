import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div>Loading...</div>;
}

// const Dashboard = Loadable({
//   loader: () => import('./views/Dashboard'),
//   loading: Loading,
// });

const SensorInventory = Loadable({
  loader: () => import('./implementation/Sensors/List'),
  loading: Loading,
});

const SensorVisualize = Loadable({
  loader: () => import('./implementation/Sensors/Visualize'),
  loading: Loading,
});

const GatewayInventory = Loadable({
  loader: () => import('./implementation/Gateways/List'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./implementation/Alerts/List'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./implementation/Notifications/Settings'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./implementation/Users/Profile'),
  loading: Loading,
});

const Billing = Loadable({
  loader: () => import('./implementation/Billing/Main'),
  loading: Loading,
});

const routes = [
  { path: '/', name: 'Home', component: SensorInventory, exact: true },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/sensors/list', name: 'Sensor Inventory', component: SensorInventory },
  { path: '/sensors/visualize', name: 'Activity Monitor', component: SensorVisualize },
  { path: '/gateways/list', name: 'Gateway Inventory', component: GatewayInventory },
  { path: '/alerts/list', name: 'Alerts', component: Alerts },
  { path: '/notifications/settings', name: 'Settings', component: Settings },
  { path: '/users/profile', name: 'Profile', component: Profile },
  { path: '/billing', name: 'Billing', component: Billing },
];

export default routes;

