// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.2.2 on 2020-02-22T22:38:29.836Z

const awsmobile = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_2yqeqSjs4",
  "aws_user_pools_web_client_id": "39gdpeih5dcot57v1a8quvruc5",
  "aws_cognito_identity_pool_id": "eu-west-1:1e1f5b76-b938-4ac5-a131-5d3590ef364a",
  "aws_appsync_graphqlEndpoint": "https://crbn2fnx55dptiqksdbhztb4uq.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AWS_IAM",
  "aws_cloud_logic_custom": [
    {
      "name": "ApiGatewayRestApi",
      "endpoint": "https://7e3v4mamm1.execute-api.eu-west-1.amazonaws.com/prod",
      "region": "eu-west-1"
    }
  ]
};

export default awsmobile;
